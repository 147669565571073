@import "../../../Styles/Mixin.scss";

.overlaySignIn {
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .formSignIn {
    margin: 80px auto;
    padding: 40px 120px;
    width: 100%;
    max-width: 660px;
    background: #fff;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    position: relative;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .formSignIn h1 {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .field {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .fieldForgot {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .forgotPassword {
      display: grid;
      place-items: center;

      border: none;
      background: transparent;
      margin-top: -16px;
      margin-bottom: 14px;
      cursor: pointer;

      &:hover {
        color: $colorPrimary;
      }
    }
  }

  .closeModal {
    display: flex;
    align-items: center;
    justify-content: center;

    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    background: $colorWhite;
    border-radius: 4px;
    transition: filter 0.3s;

    position: absolute;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 800px) {
    .formSignIn {
      padding: 40px 40px;
      margin: 0 20px;
    }
  }

  .cardModal {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 480px;
    border-radius: 12px;
    padding: 32px 12px;

    background: #fff;

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .infoModal {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
      }

      .errorModal {
        font-size: 32px;
        font-weight: bold;
        color: $colorBlack;
        margin-top: 14px;
      }

      .messageModal {
        font-size: 18px;
        font-weight: normal;
        color: $colorBlack;
        margin-top: 8px;
      }
    }

    .buttonModal {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 20px;

      .buttonCancel {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px 16px;
        border: none;
        border-radius: 8px;
        background: #e81e26;
        color: #fff;
        cursor: pointer;

        transition: filter 0.3s;

        &:hover {
          transition: filter 0.3s;
          filter: brightness(0.9);
        }
      }
    }
  }
}
