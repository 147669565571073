@import "../../../Styles/Mixin.scss";

.podcastInternalMusic {
  height: 800px;
  max-height: 60vh;
  position: relative;
  z-index: 1;

  .podcastInternalMusicSkeleton {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 500px;
    border-radius: 0.75rem;

  }

  p {
    font-size: 14px;
    color: $colorBlack;
  }

  td {
    cursor: pointer;
  }

  .podcastInternalMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;

    .image {
      position: relative;
      border-radius: 4px;
      width: 100%;
      height: 220px;

      img {
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
        object-position: top center;
      }
    }

    .player {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      width: 240px;

      .imagePlayer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 1px;

        width: 88px;
        height: 88px;

        background: $colorPrimary;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -44px;
        margin-left: 20px;
        z-index: 20;
        flex-shrink: 0;
      }

      .infoPlayer {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-left: 20px;

        span {
          white-space: nowrap;
          font-size: 16px;
          line-height: 130%;
          font-weight: normal;
          color: $colorBlack;
        }

        .sectionShare {
          display: flex;
          align-items: center;
          gap: 20px;

          .buttonShare {
            display: grid;
            place-items: center;

            width: 32px;
            height: 32px;

            border: none;
            border-radius: 4px;
            background: transparent;
            cursor: pointer;

            svg {
              path {
                fill: #252428;
              }
            }

            transition: color 0.3s;

            &:hover {
              transition: color 0.3s;

              svg {
                path {
                  fill: #333335;
                }
              }
            }

          }

          .buttonsSharelink {
            display: flex;
            align-items: center;
            gap: 14px;

            visibility: hidden;
            opacity: 0;
            transform:
              translate(-100%, 100%) scale(0);

            transform-origin: 100% -50%;
            transition: all 0.35s;

            &.isShare {
              visibility: visible;
              opacity: 1;
              transform: translate(0) scale(1);
            }

            .react-share__ShareButton {
              display: grid;
              place-items: center;
            }
          }
        }
      }
    }
  }

  .listPodcast {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    table {
      width: 100%;
      border-spacing: 0 0.5rem;

      th {
        font-size: 12px;
        font-weight: 600;
        color: #bcbdbf;
        line-height: 300%;
        text-transform: uppercase;
        padding: 4px;
        text-align: left;

        border-bottom: 1px solid rgba(100, 100, 100, 0.2);
      }

      td {
        font-size: 12px;
        font-weight: normal;
        line-height: 130%;
        color: $colorBlack;
        padding: 8px 4px;
        border: 0;

        &:first-child {
          font-weight: 500;
        }
      }
    }

    tr {
      &:hover {
        background: rgba(146, 161, 237, 0.2);
      }
    }

    .MusicActive {
      background: rgba(146, 161, 237, 0.2);

      td {
        font-weight: 600;
      }
    }
  }

  &.play {
    padding-bottom: 120px;
  }
}