@import "../../../Styles/Mixin.scss";

.detailed-news {
  margin-bottom: 8px;
  .infos-head {
    margin-top: 8px;
    padding-bottom: 20px;
    p {
      font-size: 14px;
      line-height: 21px;
      color: $colorBlack;
      font-weight: normal;
      margin: 0;
    }
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: normal;
      color: $colorPrimary;
      margin-top: 12px;
    }
  }
  .image {
    display: flex;
    align-items: center;
    width: 100%;
    height: 425px;
    min-width: 130px;
    background-size: cover;
    background-repeat: none;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    > div {
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: 480px;
      object-fit: scale-down;
      border-radius: 12px;
      z-index: 2;
    }
    .blur {
      width: 100%;
      height: 100%;
      filter: blur(20px);
    }
    &.placeholder {
      background-position: center center;
    }
  }
  .info {
    margin-top: 20px;
    iframe {
      padding: 0px 13%;
      box-sizing: border-box;
      background: #333;
      border-radius: 5px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
      color: $colorBlack;
      margin-top: 10px;
    }

    pre {
      white-space: normal;
    }
    h3 {
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  // @media(max-width: 800px) {
  //   .image {
  //     height: 165px;
  //   }
  // }
}
