@import "../../Styles/Mixin.scss";

$col: 8.33%;

.row {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  .column {
    margin: 0px 10px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
    &.c-1 {
      width: $col;
    }
    &.c-2 {
      width: ($col * 2);
    }
    &.c-3 {
      width: ($col * 3);
    }
    &.c-4 {
      width: ($col * 4);
    }
    &.c-5 {
      width: ($col * 5);
    }
    &.c-6 {
      width: ($col * 6);
    }
    &.c-7 {
      width: ($col * 7);
    }
    &.c-8 {
      width: ($col * 8);
    }
    &.c-9 {
      width: ($col * 9);
    }
    &.c-10 {
      width: ($col * 10);
    }
    &.c-11 {
      width: ($col * 11);
    }
    &.c-12 {
      width: ($col * 12);
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .column {
      width: 100% !important;
      margin: 0;
      margin-top: 16px;
    }
  }
}
