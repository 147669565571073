@import "../../Styles/Mixin.scss";

#player-alpha {
    background-color: $colorPrimary;
    padding: 8px;
    height: 56px;
    width: 120px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: width 0.3s ease;

    &.openAlpha {
        width: 100%;
    }

    span {
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        margin-right: 6px;
    }

    .infos {
        .top {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            span {
                font-size: 9px;
                line-height: 12px;
                font-weight: 500;
                color: #fff;
                u {
                    text-decoration: none;
                    margin: 0px 9px;
                }
            }
            svg {
                width: 10px;
                margin-left: 9px;
            }
        }
        .music {
            font-size: 11px;
            font-weight: 700;
            color: rgba(#fff, 0.5);
            b {
                font-weight: 800;
            }
        }
    }
    .album {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 44px;
        height: 44px;
        border-radius: 4px;

        background-color: rgba(#fff, 0.2);
        background-size: cover;
        svg {
            width: 28px;
        }
        #pause {
            display: none;
        }
        #play {
            display: none;
        }
        &.pause {
            #play {
                display: block;
            }
        }
        &.play {
            #pause {
                display: block;
            }
        }
    }
}

#page-player-alpha {
    // background-color: $colorPrimary;
    padding: 8px;
    // height: 30rem;
    width: 24rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    transition: width 0.3s ease;

    &.openAlpha {
        width: 100%;
        height: 100%;
        flex-wrap: wrap;

        span {
            font-size: 2rem;
        }

        input {
            accent-color: $colorPrimary;
        }

        .infos {
            .top {
                span {
                    font-size: 1rem;
                }

                svg {
                    width: 2rem;
                }
            }

            .music {
                font-size: 1.75rem;
            }
        }

        .album {
            width: 19rem;
            height: 19rem;

            svg {
                width: 4rem;
            }
        }
    }

    span {
        font-size: 2rem;
        font-weight: bold;
        color: $colorPrimary;
        margin-right: 6px;
    }

    .infos {
        .top {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            span {
                font-size: 9px;
                line-height: 12px;
                font-weight: 500;
                color: $colorPrimary;
                u {
                    text-decoration: none;
                    margin: 0px 9px;
                }
            }
            svg {
                width: 10px;
                margin-left: 9px;
            }
        }
        
        .music {
            font-size: 11px;
            font-weight: 700;
            color: rgba($colorPrimary, 0.5);
            b {
                font-weight: 800;
            }
        }
    }

    .album {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 5rem;
        height: 5rem;
        border-radius: 4px;

        background-color: rgba(#fff, 0.2);
        background-size: cover;
        svg {
            width: 2rem;
        }
        #pause {
            display: none;
        }
        #play {
            display: none;
        }
        &.pause {
            #play {
                display: block;
            }
        }
        &.play {
            #pause {
                display: block;
            }
        }
    }

    .letra {
        background: rgba($colorPrimary, 0.05);
        border-radius: 1rem;
        padding: 2rem;
        height: 21rem;
        min-width: 25rem;
        display: flex;
        justify-content: center;
        overflow-y: auto;
        // display: flex;
        // align-items: center;

        &::-webkit-scrollbar {
            width: 12px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: rgba($colorPrimary, 0.25);        /* color of the tracking area */
        }
              
        &::-webkit-scrollbar-thumb {
            background-color: #FFF;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid rgba($colorPrimary, 0.5);  /* creates padding around scroll thumb */
        }
    }
}
