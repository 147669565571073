@import "../../../Styles/Mixin.scss";

.playlistInternalHeader {
  .subtitlePlaylist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    p {
      font-size: 14px;
      color: $colorBlack;
    }

    .sectionShare {
      display: flex;
      align-items: center;
      gap: 20px;

      .buttonShare {
        display: grid;
        place-items: center;

        width: 32px;
        height: 32px;
        margin-right: 12px;

        border: none;
        border-radius: 4px;
        background: transparent;
        cursor: pointer;

        svg {
          path {
            fill: #252428;
          }
        }

        transition: color 0.3s;
        &:hover {
          transition: color 0.3s;
          svg {
            path {
              fill: #333335;
            }
          }
        }
      }
      .buttonsSharelink {
        display: flex;
        align-items: center;
        gap: 14px;

        visibility: hidden;
        opacity: 0;
        transform: translate(50%, 100%) scale(0);

        transform-origin: 50% -50%;
        transition: all 0.35s;

        &.isShare {
          visibility: visible;
          opacity: 1;
          transform: translate(0) scale(1);
        }

        .react-share__ShareButton {
          display: grid;
          place-items: center;
        }
      }
    }
  }

  .playlistInternalMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;

    iframe {
      width: 100%;
      height: 680px;
      border: none;
      border-radius: 4px;
    }
  }
}
