@import "../../../Styles/Mixin.scss";

.ContainerPlaylist {
  p {
    font-size: 14px;
    color: $colorBlack;
  }
}

.playlistItems {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(252px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
