@import "../../../Styles/Mixin.scss";

.containerSignIn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .profileRegister {
    width: 45%;
    .contentSignIn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      form {
        display: flex;
        flex-direction: column;
  
        width: 100%;
        margin-top: 20px;
      }
      form .field {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      form .fieldGroup .field + .field {
        margin-left: 24px;
      }
    }
  }
}