@import "../../../Styles/Mixin.scss";

.podcast {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-decoration: none;

    .image {
        .wallpaper {
            border-radius: 4px;
            width: 100%;
            height: 100%;
        }
    }
    .cardImage {
        display: flex;
        flex-direction: column;
        padding: 12px;
        background-color: $colorWhite;
        border-radius: 4px;
        text-decoration: none;
        
        .cardImage_skeleton {
            width: 100%;
            max-width: 100%;
            min-height: 120px;
            height: 100%;
            max-height: 100%;
        }

        .imagess {
            display: grid;
            place-items: center;

            img {
                width: 100%;
                min-height: 120px;

                border-radius: 8px;
                object-fit: cover;
            }
        }
    }

    .cardDate {
        font-size: 10px;
        font-weight: normal;
        line-height: 15px;
        max-height: 30px;
        color: $colorPrimary;

        margin-top: 17px;
    }

    .cardTitle {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 290px;
        margin-top: 6px;
        p {
            text-transform: capitalize;
        }
    }

    .cardSubTitle {
        max-width: 60ch;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $colorBlack;
        height: 35px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
    }

    .cardLink {
        font-size: 8px;
        font-weight: 500;
        line-height: 12px;
        text-transform: uppercase;
        color: #92a1ed;
        margin-top: 15px;
    }
}
