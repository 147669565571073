@import "../../../Styles/Mixin.scss";

.columnistsItems {
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .columnQuestion {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: bold;
      color: $colorBlack;
      max-width: 32ch;
    }

    .buttonQuestion {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 10px 16px;
      border-radius: 4px;
      border: none;

      background: $colorPrimary;
      color: $colorWhite;
      cursor: pointer;
      transition: all 0.2s;
      transform: scale(.97);
      &:hover {
        transform: scale(1);
      }

      svg {
        margin-left: 12px;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
  }
}
