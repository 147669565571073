@import "../../../Styles/Mixin.scss";

.filterSchedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .buttons {
    margin-bottom: 16px;
    button {
      padding: 6px 11px;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    .item {
      background-color: #fff;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-decoration: none;
      img {
        width: 100%;
        border-radius: 4px;
      }
      b {
        font-size: 14px;
        line-height: 21px;
        color: #252428;
        margin-top: 15px;
      }
      u {
        color: $colorPrimary;
        font-size: 8px;
        margin-bottom: 10px;
        text-transform: uppercase;
        margin-top: 4px;
      }
    }
  }

  .announcedFilter {
    margin-top: -40px;
  }

  @media (max-width: 800px) {
    .buttons {
      width: 100%;
      flex-direction: column;
      button {
        margin-left: 0px;
        margin-bottom: 4px;
        width: 100%;
      }
    }

    .announcedFilter {
      display: none;
    }
  }
}
