@import "../../Styles/Mixin.scss";

.overlayModalDefault {
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;    
    .modalDefault {
        display: flex;
    }
}    


