@import "../../../Styles/Mixin.scss";

.columnistsPodcast {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  
  .imagess {
    width: 100%;
    min-height: 210px;

    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .infos {
    border-bottom: 1px solid #fff;
    margin-top: 20px;
    padding-bottom: 24px;

    b {
      font-size: 14px;
      line-height: 15px;
      color: $colorPrimary;
      font-weight: 500;
    }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: $colorBlack;
      margin-top: 6px;
    }
  }
}
