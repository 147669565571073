@import "../../../Styles/Mixin.scss";

#ContainerNotfound {
  display: flex;
  width: 100%;
  

  .notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    width: 80%;
    margin-top: 80px;
  
    h1 {
      font-size: 18px;
      font-weight: 700;
      color: $colorPrimary;
      text-transform: uppercase;
      margin: 0;
    }
  
    span {
      font-size: 12px;
      color: $colorBlack;
      margin-top: 4px;
    }
  }
  .AnnouncedSchedule {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
  }
}
