@import "../../../Styles/Mixin.scss";

.competition {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;

}
.buttonPLus {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  button {
    width: 100%;
    height: 40px;
  }
}
