@import "../../../Styles/Mixin.scss";

#input-search {
    display: flex;
    flex: 1;
    // margin: 0px 20px;
    background-color: rgba($colorPrimary, .2);
    border-radius: 4px;
    input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 11px;
        line-height: 15px;
        color: $colorBlack;
        padding: 0px 33px;
        &:hover, &:focus {
            background-color: rgba($colorPrimary, .05);
        }
    }
    button {
        background: transparent;
        outline: none;
        border: none;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            transition: .2s;
        }
        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }

    @media(max-width: 955px) {
        display: none;
      }
}