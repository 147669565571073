@import "../../Styles/Mixin.scss";

.overlayModal {
    background: rgba($colorSecondary, $alpha: 0.8);
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;    
    .modalTerms {
        max-width: 800px;                                
        .top {
            background: $colorPrimary;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            h2 {
                font-weight: 500;                
                margin: 0;
            }
            div.closeModal {
                display: flex;
                align-items: center;
                justify-content: center;
            
                width: 32px;
                height: 32px;
                cursor: pointer;
                
                background: $colorWhite;
                border-radius: 4px;
                transition: filter 0.3s;
                            
            
                &:hover {
                  filter: brightness(0.9);
                }
            }            
        }
        h2 {
            color: $colorWhite;
        }
        .terms {
            height: 400px;
            padding: 40px;
            background: $colorWhite;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;            
            overflow: scroll;           
        }
        .terms span{            
            color: $colorGray;
            text-align: justify;
            p {
                margin-top: 10px;
            }            
        }
    }
}    


