#pix {
  width: 70%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 58%; 
  top: 80%; 
  transform: translate(-50%, -50%);
  z-index: 50;  

  img {
    width: 100%;
  }

  @media(max-width: 800px) {
    left: 50%;
    top: 86%;
    width: 90%;
  }
}
.bottom {
  top: 95% !important;
}
.top {
  top: 82% !important;
} 


#announcedPLay {
  width: 60%;
  position: absolute;
  left: 65%;
  top: 100%;
  transform: translate(-50%, -50%);

  @media(max-width: 800px) {
    left: 68%;
    top: 100%;
    width: 62%;
  }
}

#announcedPLayList {
  width: 90%;
  border-radius: 4px;

  img {
    width: 100%;
  }
}

#announcedNewsInternal {
  width: 470px;
  height: 60px;
  position: absolute;
  bottom: -30px;
}

#announcedNewsSchedule {
  width: 120px;
  height: 600px;
}

#AnnouncedFilter {
  width: 470px;
  height: 60px;
}
