@import "../../../Styles/Mixin.scss";

.scheduleItemsInternal {
  > a {
    text-decoration: none;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  .image {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    .wallpaper {
      border-radius: 4px;
      width: 100%;
    }
  }

  .infos {
    border-bottom: 1px solid #fff;
    margin-top: 20px;
    padding-bottom: 24px;

    span {
      font-size: 10px;
      line-height: 15px;
      color: $colorPrimary;
      font-weight: 500;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $colorBlack;
      margin-top: 6px;
    }

    h4 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $colorBlack;
      margin-top: 8px;
    }

    a {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 8px;
      line-height: 12px;
      color: $colorDarkBlue;
      margin-top: 16px;
    }
  }
}
