@import "../../../Styles/Mixin.scss";

.categorieButton {
  margin-bottom: 20px;  
}

.categoryItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.containerLoadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}