@import "../../Styles/Mixin.scss";

#playerClassics {
    background: linear-gradient(88.74deg, rgba(0, 62, 156, 1) 0%, rgba(255, 223, 0, 0) 100%);
    padding: 8px;
    height: 56px;
    width: 120px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: width 0.3s ease;



    &.openClassics {
        width: 100%;
    }

    span {
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        margin-right: 6px;
    }

    .infos {
        .top {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            span {
                font-size: 9px;
                line-height: 12px;
                font-weight: 500;
                color: #fff;
                u {
                    text-decoration: none;
                    margin: 0px 9px;
                }
            }
            svg {
                width: 10px;
                margin-left: 9px;
            }
        }
        .music {
            font-size: 11px;
            font-weight: 700;
            color: rgba(#fff, 0.5);
            b {
                font-weight: 800;
                color: #fff;
            }
            span {
                font-weight: 500;
                color: #fff;
            }
        }
    }

    .album {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 44px;
        height: 44px;
        border-radius: 4px;

        background-color: rgba(#fff, 0.2);
        background-size: cover;
        svg {
            width: 28px;
        }
        #pause {
            display: none;
        }
        #play {
            display: none;
        }
        &.pause {
            #play {
                display: block;
            }
        }
        &.play {
            #pause {
                display: block;
            }
        }
    }
}

#page-playerClassics {
    // background: linear-gradient(88.74deg, rgba(0, 156, 59, 0.77) 0%, rgba(255, 223, 0, 0.8) 100%);
    padding: 8px;
    // height: 30rem;
    width: 24rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: width 0.3s ease;

    &.openClassics {
        width: 100%;
        // height: 100%;
        flex-wrap: wrap;

        span {
            font-size: 2rem;
        }
    
        .infos {
            .top {
                span {
                    font-size: 1rem;
                }

                svg {
                    width: 2rem;
                }
            }

            .music {
                font-size: 1.75rem;
            }
        }
    
        .album {
            width: 20rem;
            height: 20rem;

            svg {
                width: 4rem;
            }
        }
    }

    span {
        font-size: 2rem;
        font-weight: bold;
        color: $colorSecondary;
        margin-right: 6px;
    }

    .infos {
        .top {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            span {
                font-size: 9px;
                line-height: 12px;
                font-weight: 500;
                color: $colorSecondary;
                u {
                    text-decoration: none;
                    margin: 0px 9px;
                }
            }
            svg {
                width: 10px;
                margin-left: 9px;
            }
        }
        .music {
            font-size: 11px;
            font-weight: 700;
            color: rgba($colorSecondary, 0.5);
            b {
                font-weight: 800;
                color: $colorSecondary;
            }
            span {
                font-weight: 500;
                color: $colorSecondary;
            }
        }
    }

    .album {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 5rem;
        height: 5rem;
        border-radius: 4px;

        background-color: rgba($colorSecondary, 0.2);
        background-size: cover;
        svg {
            width: 2rem;
        }
        #pause {
            display: none;
        }
        #play {
            display: none;
        }
        &.pause {
            #play {
                display: block;
            }
        }
        &.play {
            #pause {
                display: block;
            }
        }
    }
}
