@import "../../Styles/Mixin.scss";

.overlayModalMessage {
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &.overleyModalVisible {
        display: flex;
    }

    .cardModal {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 480px;
        border-radius: 12px;
        padding: 32px 12px;

        background: #fff;

        .cardContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .infoModal {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
            }

            .errorModal {
                font-size: 32px;
                font-weight: bold;
                color: $colorBlack;
                margin-top: 14px;
            }

            .messageModal {
                font-size: 18px;
                font-weight: normal;
                color: $colorBlack;
                margin-top: 8px;
            }
        }

        .buttonModal {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-top: 20px;

            .buttonSucess {
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 10px 16px;
                border: none;
                border-radius: 8px;
                background: #0aa06e;
                color: #fff;
                cursor: pointer;
                transition: filter 0.3s;

                &.error {
                    background: #e81e26;
                }

                &:hover {
                    transition: filter 0.3s;
                    filter: brightness(0.9);
                }

            }
        }
    }
}
