@import "../../../Styles/Mixin.scss";

#playerPodcast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 184px);
  // padding: 20px;
  height: 146px;
  background: #e1e5f4;
  position: fixed;
  z-index: 10;
  /* left: 185px; */
  visibility: hidden;
  opacity: 0;
  bottom: -100px;
  transition: all .2s;
  margin: 0 -24px;
  
  &.active {
    bottom: 0;
    visibility: visible;
    opacity: 1;
  }
  .playerMusicAlbum {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;

    .imageAlbum {
      width: 120px;
      height: 105px;
      border-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }

    .infoAlbum {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      margin-left: 30px;

      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        margin: 0;
        margin-bottom: 5px;
      }
      span {
        font-size: 12px;
        font-weight: 600;
        color: $colorGray;
        margin-bottom: 2px;
      }
      cite {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: $colorGray;
      }
    }
  }
  .ExectionPlayer {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 50%;

    .playerPause {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .playpause {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 52px;
        height: 52px;

        background: $colorPrimary;
        border-radius: 50%;
        margin: 0 40px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .progreesPlayer {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      margin-top: 20px;
      transition: all .2s;
      visibility: hidden;
      opacity: 0;
      margin-bottom: -35px;
      &.active {
        margin-bottom: 0px;
        opacity: 1;
        visibility: visible;
      }
      span {
        font-size: 10px;
        color: $colorPrimary;
      }

      .progress {
        width: 420px;
        height: 4px;
        background: rgba(66, 91, 223, 0.2);
        border-radius: 4px;
        margin: 0 12px;

        > div {
          height: 100%;
          background: $colorPrimary;
          border-radius: 4px;
        }
      }
    }
  }
  .audioPlayer {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25%;
    margin-right: 20px;
  }

  @media(max-width: 800px) {
    width: 100%;
    margin: 0 -14px;

    .playerMusicAlbum {
      margin-left: 10px;

      .imageAlbum {
        display: none;
      }

      .infoAlbum {
        margin-left: 0;
      }
    }
    
    .ExectionPlayer {
      .playerPause {
        .playpause {
          width: 42px;
          height: 42px;

          margin: 0 14px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .progreesPlayer {
        width: 60%;
      }
    }

    .audioPlayer {
      margin-right: 10px;


      svg {
        width: 20px;
        height: 20px;

      }

      input[type="range"] {
        // -webkit-transform:rotate(270deg);
        // -moz-transform:rotate(270deg);
        // -o-transform:rotate(270deg);
        // -ms-transform:rotate(270deg);
        // transform:rotate(270deg);

        width: 50%;
      }
    }
  }
}