$colorPrimary: #203ac2;
$colorSecondary: #000;
$colorWhite: #ffffff;
$colorAction: #eaeaea;

$colorGray: #646464;
$colorBlack: #252428;
$colorBlackNav: #2b2a2f;
$colorDarkContent: #2b2a2f;
$colorDarkWhite: #efefef;
$colorDarkBlue: #92a1ed;
$colorDarkLineContent: #f5f6f6;
$colorDarkSpan: #e5e5e5;
