@import "../../../Styles/Mixin.scss";

.destaques {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    .buttons {
        margin-bottom: 21px;
        button {
            flex: 1;
        }
    }
    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

// .InfiniteCarousel{
//     padding: 23px;
// }

.InfiniteCarouselArrowIcon{
    
    display: inline-block!important;
    padding: 10px!important;
    border: solid #203ac2!important;
    border-width: 0 5px 5px 0!important;
}

.InfiniteCarouselFrame {
    // width: 100%;
    // overflow: hidden;
    // margin-left: 30px;
    padding: 12px;
    background-color: #fff;
    text-decoration: none;
    margin-bottom: 20px;
}

.titulo {
    grid-column: 1/-1;
    max-width: 60ch;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
    color: #252428;
    height: 45px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    border-radius: 4px;
    margin-left: 18px;
}

.InfiniteCarouselSlide img {
    // width: 100%;
    width: 24vw !important
    // margin-left: 18px;
}

.InfiniteCarouselSlide {
    position: relative;
    float: left;
    display: inline-block;
    width: 493.781px;
    height: auto;
    margin: 0px 10px;
    opacity: 1;
}

.InfiniteCarouselArrow {
    display: block;
    background: none;
    border: none;
    position: absolute;
    top: 40%;
    z-index: 2;
    outline: none;
    transform: translateY(-50%);
    cursor: pointer;
}

@media (max-width: 800px){
    .InfiniteCarouselArrowIcon{
    
        display: inline-block!important;
        padding: 10px!important;
        border: solid #203ac2!important;
        border-width: 0 5px 5px 0!important;
    }
    
    .InfiniteCarouselFrame {
        // width: 100%;
        // overflow: hidden;
        // margin-left: 30px;
        padding: 10px;
        background-color: #fff;
        text-decoration: none;
        flex-direction: column;
        margin-bottom: 20px
}

.titulo {
    font-size: 14px;
    color: #252428;
    max-width: 35ch;
    height: 35px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
}

.InfiniteCarouselSlide img {
    // width: 85% !important;
    width: 75% !important;
    margin-left: 15px;
}
}