@import "../../../Styles/Mixin.scss";

.PromotionNews-news {
  margin-bottom: 8px;
  .PromotionNews-head {
    padding-bottom: 14px;
    p {
      font-size: 14px;
      line-height: 21px;
      color: $colorBlack;
      font-weight: normal;
      margin: 0;
    }
    span {
      font-size: 10px;
      line-height: 15px;
      font-weight: normal;
      color: $colorPrimary;
      margin-top: 12px;
    }
  }
  .image {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    > span {
      width: 100%;
    }
    .wallpaper {
      border-radius: 4px;
      width: 100%;
    }

    .rowQuestion {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 16px 0;
      .buttonPromotion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 64px;
        border-radius: 4px;
        border: none;
  
        background: $colorPrimary;
        color: $colorWhite;
        cursor: pointer;
        transition: all 0.2s;
        transform: scale(.97);
        &:hover {
          transform: scale(1);
        }
      }
    }

  }

  .info {
    margin-top: 0px;
    p {
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
      color: $colorBlack;
    }

    &.result {
      margin-top: 40px;

      p {
        font-size: 16px;
      }
    }
  }

  .containerModalPromo {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 4px;
    position: relative;

    padding: 40px;

    .titleModalPromo {
      display: flex;
      flex-direction: column;

      width: 100%;
      border-radius: 4px 4px 0 0;
      padding: 20px;

      background: $colorPrimary;

      h2 {
        font-size: 28px;
        font-weight: 700;
        color: $colorWhite;
        max-width: 32ch;
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
        color: #efefef;
        margin-top: 4px;
      }
    }

    .subtitleModalPromo {
      display: flex;
      flex-direction: column;

      background: $colorWhite;
      padding: 10px 20px;

      p {
        font-size: 18px;
        font-weight: 700;
        color: $colorBlack;
        margin-top: 10px;
      }

      textarea {
        border: 1px solid #646464;
        border-radius: 6px;
        background: transparent;
        padding: 12px;

        font-size: 15px;
        font-weight: 700;
        color: $colorBlack;

        resize: none;
        margin-top: 12px;
      }
    }

    .footerModalPromo {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 20px;

      background: #F3F5F7;
      border-radius: 0 0 4px 4px;

      .buttonModalPromo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 40px;
        border-radius: 4px;
        border: none;

        background: $colorPrimary;
        color: $colorWhite;
        cursor: pointer;
        transition: all 0.2s;
        transform: scale(.97);
        &:hover {
          transform: scale(1);
        }
      }

    }
    .closeModalPromo {
      display: grid;
      place-items: center;

      border: none;
      border-radius: 4px;
      cursor: pointer;

      position: absolute;

      top: 60px;
      right: 60px;

      transition: filter 0.4s;

      &:hover {
        transition: 0.4s;
        filter: brightness(0.9);
      }
    }

    @media(max-width: 800px) {
      width: 100%;

      .titleModalPromo {
        h2 {
          font-size: 22px;
        }
        h3 {
          font-size: 14px;
          margin-top: 14px;
        }
      }

      .subtitleModalPromo {
        p {
          font-size: 14px;
        }
      }
    }
  }
  .cardModal {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 480px;
    border-radius: 12px;
    padding: 32px 12px;

    background: #fff;

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .infoModal {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
      }

      .errorModal {
        font-size: 32px;
        font-weight: bold;
        color: $colorBlack;
        margin-top: 14px;
      }

      .messageModal {
        font-size: 18px;
        font-weight: normal;
        color: $colorBlack;
        margin-top: 8px;
      }
    }

    .buttonModal {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 20px;

      .buttonCancel {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px 16px;
        border: none;
        border-radius: 8px;
        background: #0AA06E;
        color: #fff;
        cursor: pointer;

        transition: filter 0.3s;

        &:hover {
          transition: filter 0.3s;
          filter: brightness(0.9);
        }
      }
    }
  }
}
