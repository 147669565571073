@import "../../../Styles/Mixin.scss";

.ao-vivo {
    margin-bottom: 10px;
    iframe {
        width: 100% !important;
        border-radius: 5px;
        background: #999;
        // height: 170px;
    }
    .image {
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        display: flex;
        .wallpaper {
           border-radius: 4px;
           width: 100%;
        }
    }
    .feedback {
        border-bottom: 1px solid #FFF;
        padding: 12px 0px;
        display: flex;
        justify-content: flex-end;
        button {
            display: flex;
            align-items: center;
            background: transparent;
            font-size: 9px;
            line-height: 12px;
            border: none;
            white-space: nowrap;
            cursor: pointer;
            transition: all .2s;
            
            svg {
                
            }
            span {
                margin-left: 8px;
                font-size: 9px;
                color: $colorBlack;
            }
        }
    }
}

@media (max-width: 800px){
    .ao-vivo {
        iframe {
            height: 270px!important;
        }
}
}