@import "../../../Styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.btn {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px 11px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(.97);
    }
    &.primary {
      border: 1px solid $colorPrimary;
      background-color: $colorPrimary;
      color: #fff;
    }
    &.gray {
      border: 1px solid $colorGray !important;
      color: #fff;
    }
    &.outline {
      background-color: transparent;
      &.gray {
        color: $colorGray !important;
      }
      &.primary {
        color: $colorPrimary;
      }
    }    
  }
  .buttons {
    display: flex;
    button {
      margin: 0px 5px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }    
  }
  @media (max-width: 780px) {
    .buttons {
      flex-direction: column;
      button {
        margin-left: 0px !important;
        margin-bottom: 5px !important;
        width: 100%;
      }
    }
  }
  
  