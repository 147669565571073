@import "../../Styles/Mixin.scss";

.headerProfile {
  display: flex;
  align-items: center;
  justify-content: center;  
  background: $colorWhite;
  height: 56px;
  border-radius: 4px;
  padding: 0 12px;
  position: relative;
  span {
    font-size: 9px;
    line-height: 12px;
    color: $colorBlack;
    b {
      color: $colorBlack;
    }
  }

  .photoProfile {
    display: grid;
    place-items: center;
    background-color: #203AC2;
    width: 44px;
    height: 44px;
    border-radius: 50%;   
    margin-right: 12px;

    img {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .userProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
      font-size: 10px;
      margin-top: 5px;
      padding: 0;
      text-decoration: underline;
      color: $colorDarkBlue;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  button.logout {
    background: transparent;
    border: none;              
  }
  

  @media(max-width: 800px) {

      justify-content: space-between;
      height: 56px;

      .photoProfile {
        width: 32px;
        height: 32px;
      }
      .userProfile {
        box-sizing: border-box;
        button{
          font-size: 10px;
        }
      }
      .user {
        margin-left: 8px;
      }
      .logout {
        margin-left: 12px;
      }      
    }

}