@import "./Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
//   margin: 0px;
//   padding: 0px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
}
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

ul li {
  list-style: none;
}

.buttons {
  display: flex;
  button {
    margin: 0px 5px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
