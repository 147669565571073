@import "../../Styles/Mixin.scss";

.overlay-play {
    button {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .5s;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            transition: all .2s;
        }
        svg {
            width: 30%;
            height: 40%;
        }
    }
    &:hover {
        button {
            background: rgba($colorPrimary, .5);
            border-radius: 4px;
            svg {
                position: relative;
                transform: scale(1.2);
                path {
                    fill: #FFF;
                }
            }
        }
    }
}