@import "../../Styles/Mixin.scss";

#layout {
  background-color: #f5f6f6;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;

  &.darkMode {
    .container .navigation section .scroll-section-dark:after {
      background: linear-gradient(to top, #252428 0%, rgba(37, 36, 40, 0) 100%);
    }
    #overlayMenu {
      .nav-bar {
        ul {
          li {
            svg {
              path {
                fill: $colorDarkBlue;
              }
            }
          }
        }

        .logo {
          &.logoDark {
            a {
              img {

              }
            }
          }
        }
      }

      .backgroundProfile {
        &.dark {
          background: $colorBlackNav;
        }
      }
      .colorNav {
        &.dark {
          color: $colorDarkWhite;
        }
        &.darkBlue {
          color: $colorDarkBlue;
        }
      }
    }

    #containerCardImage {
      .cardImage {
        &.cardImageDark {
          background: $colorDarkContent;
        }
        span {
          color: $colorDarkBlue;
        }
        h2 {
          color: $colorWhite;
        }
        h3 {
          color: #bcbdbf;
        }
        .filterDark {
          img {
            filter: brightness(0.7);
          }
        }
      }
    }

    #containerCardImageDescription {
      .cardImage {
        span {
          color: $colorDarkBlue;
        }
        h2 {
          color: $colorWhite;
        }
        h3 {
          color: #bcbdbf;
        }
        .filterDark {
          img {
            filter: brightness(0.7);
          }
        }
      }
    }

    #containerCardSchedule {
      .cardImage {
        &.cardImageDark {
          background: $colorDarkContent;
        }
        span {
          color: $colorDarkBlue;
        }
        h2 {
          color: $colorWhite;
        }
        h3 {
          color: #bcbdbf;
        }
        .filterDark {
          img {
            filter: brightness(0.7);
          }
        }
      }
    }
    .color {
      &.dark {
        color: $colorWhite;
      }
    }
    .background {
      &.dark {
        background: $colorBlack;
      }
    }

    .backgroundPodcast {
      &.dark {
        background: $colorDarkContent;
      }
    }
    .buttonsDark {
      &.dark {
        border: 1px solid #bcbdbf;
        color: #bcbdbf;
      }
    }
    .itemDark {
      &.dark {
        background: $colorDarkContent;
      }
    }
    .titleDark {
      h1 {
        color: $colorWhite;
      }
    }
    .inpurDark {
      color: #fff;
      background-color: $colorDarkContent;
      input {
        color: #fff;
        &:hover,
        &:focus {
          background-color: rgba($colorDarkContent, 0.5);
        }
      }
    }
    .darkFill {
      svg {
        path {
          fill: $colorDarkBlue;
        }
      }
    }
    span {
      color: $colorDarkSpan;
    }
    b,
    h3 {
      color: #fff;
    }
    h4 {
      color: #bcbdbf;
    }
    p {
      color: #bcbdbf;
    }
    u,
    a {
      color: $colorDarkBlue;
    }

    table {
      td {
        color: $colorDarkWhite;
      }
    }

    .playerMusicDark {
      background: $colorDarkContent;

      .audioPlayer {
        svg {
          path {
            fill: $colorWhite;
          }
        }
      }
    }

    .settings {
      .editColumnsDark {
        background-color: #2b2a2f;
        svg {
          path {
            fill: #eaeaea;
          }
        }
      }
    }

    #menu-profile {
      background: #2b2a2f;

      button {
        color: #fff;
      }

      .user {
        span {
          color: $colorWhite !important;
          b {
            color: $colorWhite !important;
          }
        }
        a {
          color: $colorDarkBlue !important;
        }
      }
      .logout {
        color: $colorDarkBlue !important;
      }

      
    }

    #containerProfile {
      .burguerMenuDark {
        background-color: $colorDarkContent;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
    .container {
      .switeButtonDark {
        background-color: $colorDarkContent;

        span {
          color: $colorWhite;
        }
      }
      .headerProfile {
        background-color: $colorDarkContent;

        span {
          color: $colorWhite;
        }
        button {
          color: $colorDarkBlue;
        }
      }
      .registrationDark {
        background-color: $colorDarkContent;
        .profileDark {
          color: #bcbdbf;
        }
        .perfilDark {
          border: 1px solid $colorGray;
          .nameDark {
            color: #bcbdbf;
          }
        }
        form {
          background: $colorDarkContent;
          label {
            color: #bcbdbf;
          }
          button {
            color: #bcbdbf;
          }
          span {
            color: rgba(255, 0, 0, 0.8);
          }
          .error {
            background: #f0f0f5;
          }
          .button {
            color: $colorWhite;
          }
        }
      }
      .terms .spanDark {
        p {
          color: $colorBlack;
        }
        b {
          color: $colorBlack
        }
      }
    }

    .registerDark {
      background-color: $colorDarkContent;

      button {
        color: $colorWhite;
      }
    }

    .overlayModalSignIn.dark {
      .closeModal {
        background-color: $colorWhite;
      }
      .closeModal:hover {
        background-color: $colorWhite;
      }
      form {
        background-color: $colorDarkContent;
        h1 {
          color: $colorWhite;
        }
        label {
          color: $colorWhite;
        }
        .registerButton {
          background-color: $colorDarkSpan;
          color: $colorDarkContent;
        }
      }
    }
    .containerSignIn.dark {
      .profileTitle {
        color: $colorWhite;
      }
      .profileDark {
        color: $colorWhite;
      }
      .containerPerfil {
        .dark {
          color: $colorWhite;
        }
      }
    }
    .containerInput label,
    .containerSelect label {
      color: $colorWhite;
    }

    .containerCardErrorDark {
      background-color: $colorDarkContent;

      h2 {
        color: $colorDarkBlue;
      }
      h3 {
        color: $colorWhite;
      }
    }

    .containerCookieDark {
      span {
        color: $colorBlack;
      }
    }

    .columnistsItems {
      .columnQuestion {
        &.columnQuestionDark {
          h2 {
            color: $colorDarkBlue;
          }
        }
      }
    }

    .podcast {
      .cardImage {
        background-color: $colorDarkContent;
        .cardTitle {
          color: $colorWhite;
        }
        .cardDate {
          color: $colorDarkBlue;
        }

        .imageDark {
          color: $colorDarkBlue;
        }
      }
    }
  }

  #overlayMenu {
    .nav-bar {
      display: flex;
      background: #fff;
      width: 260px;
      display: flex;
      flex-direction: column;

      height: 100vh;

      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      animation: fadeInLeft 0.5s;

      // transition: all 0.5s;

      .logo {
        height: 46px;
        border-left: 4px solid $colorPrimary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 55px;
        filter: contrast(110%);
        
        a {
          img {
            width: 152px;
            margin-left: 12px;
          }
        }
      }
      nav {
        position: relative;

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            height: 20px;
            margin: 18px 0px;
            transition: all 0.2s;
            border-right: 0px solid transparent;
            * {
              transition: all 0.2s;
            }
            &.active {
              border-right: 2px solid $colorPrimary;
              a {
                span {
                  color: $colorPrimary;
                }
                svg {
                  path {
                    fill: $colorPrimary;
                  }
                }
              }
            }
            &:hover,
            &.active {
              a {
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            a {
              display: flex;
              align-items: center;
              text-decoration: none;
              svg {
                margin-left: 23px;
              }
              span {
                color: $colorBlack;
                margin-left: 12px;
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
          .accordion {
            display: flex;
            align-items: center;
            flex-direction: column;

            width: 100%;
            margin: 18px 0px;
            position: relative;

            .accordionInner {
              display: flex;
              align-items: center;
              gap: 4px;
              width: 100%;
              height: 0;
              transition: height 0.3s;

              &.activeUl {
                transition: height 0.3s;
                height: inherit;
              }
            }

            .accordionHeader {
              display: flex;
              align-items: center;
              // justify-content: space-between;
              gap: 14px;
              width: 100%;
              padding-left: 24px;
              height: 32px;
              border: none;
              background: transparent;
              font-size: 14px;
              color: $colorBlack;
              cursor: pointer;
              z-index: 20;
              position: relative;

              &:hover,
              &.activeItem {
                svg:nth-child(1) {
                  width: 20px;
                  height: 20px;
                }
              }

              svg {
                transition: all 0.3s;
                path {
                  fill: $colorBlack;
                }
              }

              svg:nth-child(2) {
                position: absolute;
                right: 32px;
              }

              &.activeItem {
                color: $colorPrimary;
                svg {
                  path {
                    fill: $colorPrimary;
                  }
                }
                svg:nth-child(2) {
                  transform: rotate(-180deg);
                }
              }
            }

            .accordionContent {
              height: 0px;
              opacity: 0;
              transition: all 0.3s;
              
              a {
                display: none;
              }

              span {
                font-size: 14px;
                color: $colorBlack;
              }

              &.activeItemLi {
                display: flex;
                align-items: center;
                width: 100%;
                height: 32px;
                margin: 0;
                border-bottom: 1px solid $colorDarkSpan;
                opacity: 1;
                transition: all 0.3s;

                a {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  margin: 0;
                  padding-left: 42px;

                  span {
                    &:hover {
                      color: $colorPrimary;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .apps {
        display: flex;
        flex-direction: column;
        padding: 23px;
        box-sizing: border-box;
        p {
          font-size: 9px;
          font-weight: 600;
          line-height: 12px;
          text-transform: uppercase;
          margin: 0px;
          padding: 0px;
          color: $colorPrimary;
          margin: 15px 0px;
        }
        a {
          width: 100%;
          &.tunein {
            background-color: #1c203c;
            min-height: 42px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }

    @media (max-width: 800px) {
      &.isNavOver {
        background: rgba(0, 0, 0, 0.8);
        opacity: 1;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
      }
      .nav-bar {
        position: absolute;
        left: -260px;
        z-index: 9999;

        &.isNav-bar {
          position: absolute;
          left: 0px;
        }
      }
    }
  }
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    header {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      margin: 0 24px;
      margin-top: 24px;

      .players {
        display: flex;
        align-items: center;
        justify-content: space-around;
        // flex-direction: column;
        // flex-wrap: wrap;
        gap: 12px;
      }

      .sectionSwiteButton {
        display: flex;
        place-items: center;
        justify-content: space-evenly;

        height: 56px;
        width: 85px;
        border-radius: 4px;
        margin-right: 12px;
        background-color: #fff;

        span {
          font-size: 10px;
          font-weight: bold;
          color: $colorBlack;
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 38px;
          height: 18px;
        }

        .switch input {
          display: none;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $colorPrimary;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 2px;
          bottom: 1px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: $colorPrimary;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
    }
    .navigation {
      display: flex;
      flex-direction: column;
      height: calc(100% - 89px);
      width: 100%;
      flex: 1;
      position: relative;
      padding-top: 32px;
      justify-content: space-between;
      section {
        height: 100%;
        width: 100%;
        .scroll-section {
          > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // margin-left: 0 !important;
            // margin-right: 0 !important;
            .page {
              margin-bottom: 40px;
            }
          }
        }
        footer {
          background-color: #252428;
          height: 70px;
          padding: 20px 0;
          width: 100%;
          display: flex;
          align-items: center;
          marquee {
            position: relative;
            a {
              font-size: 10px;
              line-height: 15px;
              color: #fff;
              &:after {
                content: "|";
                color: #92a1ed;
                margin: 0px 20px;
              }
            }
            &:before {
              width: 250px;
              height: 100px;
              position: absolute;
              left: 0px;
              top: 0px;
              content: "";
              z-index: 2;
              background: linear-gradient(to right, #252428, transparent);
            }
            &:after {
              width: 250px;
              height: 100px;
              position: absolute;
              right: 0px;
              top: 0px;
              content: "";
              background: linear-gradient(to left, #252428, transparent);
            }
          }
          .powered {
            margin-left: 28px;
            white-space: nowrap;
            margin-right: 10px;
            font-size: 12px;
            color: #ccc;
            display: flex;
            align-items: center;
            a {
              margin-left: 4px;
              color: orange;
              font-weight: 500;
              text-decoration: none;
              display: flex;
              align-items: center;
              svg {
                width: 25px;
                height: 25px;
                margin-left: 4px;
              }
            }
          }
          .social {
            display: flex;
            margin-right: 48px;
            a {
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f5f6f6;
              width: 20px;
              height: 20px;
              margin-left: 7px;
              text-decoration: none;
              i {
                color: #252428;
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 800px) {
      .players {
        display: flex;
        align-items: center;
        justify-content: space-around;
        // flex-direction: column;
        flex-wrap: wrap;
        gap: 12px;
      }
      
      section {
        .scroll-section {
          // > div {
          //   margin-left: -17px !important;
          // }
        }
      }
      header {
        flex-direction: column-reverse;
        margin: 0 14px;
        margin-top: 12px;
      }
    }

    @media (max-width: 800px) {
      .navigation {
        footer {
          .powered {
            flex-direction: column;
          }
        }
      }
    }
  }

  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #3FE05C;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 60px;
    font-size: 28px;
    cursor: pointer;
    z-index: 9999;

    i {
      color: #fff;
    }
  }
}

// .cls-1{
//   fill:#fff;
// }

// .cls-2{
//   fill:#005f27;
// }

// .cls-3{
//   fill:#46962b;
// }

// .cls-4{
//   fill:#95c11f;
// }