@import "../../Styles/Mixin.scss";

.widget {
    display: flex;
    flex-direction: column;
    position: relative;
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        >div {
            width: 100%;
        }
        h2 {
            font-size: 18px;
            line-height: 27px;
            color: $colorPrimary;
            margin: 0px;
            padding: 0px;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // max-width: 85%;
        }
        > svg {
            margin-left: 10px;
        }
        .settings {
            position: absolute;
            right: 0px;
            z-index: 5;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            button.edit {
                padding: 5px;
                border: none;
                background: rgba(#eaeaea, 1);
                border-radius: 5px;
                display: flex;
                cursor: pointer;
                position: relative;
                cursor: move;
                svg {
                    width: 20px;
                    height: 20px;
                }
                &:after {
                    width: 30px;
                    height: 30px;
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: -30px;
                    // background: linear-gradient(to left, #f5f6f6, transparent);
                }
            }
            &.active {
                .settings-menu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 30px;
                }
            }
            .settings-menu {
                right: 0px;
                display: flex;
                position: absolute;
                background: #FFF;
                padding: 10px;
                box-shadow: 0px 10px 10px rgba(#000, .1);
                border-radius: 5px;
                opacity: 0;
                visibility: hidden;
                transition: all .2s;
                margin-top: 20px;
                button {
                    padding: 3px;
                    display: flex;
                    margin: 0px 4px;
                    background: transparent;
                    border: 1px solid rgba(#000, .2);
                    cursor: pointer;
                    &.active {
                        background: $colorPrimary;
                        svg g rect {
                            fill: #FFF;
                            color: #FFF;
                        }
                    }
                    svg {
                        width: 10px;
                        height: 10px;
                        margin: 0px;
                    }
                }
            }
        }
    }
    .content {
        margin-top: 24px;
    }
}