@import "../../../Styles/Mixin.scss";

.overlaySignIn {
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .containerForgoten {
    margin: 80px auto;
    padding: 40px 120px;
    width: 100%;
    max-width: 660px;
    background: #fff;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    position: relative;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      font-size: 24px;
      color: $colorPrimary;
    }

    span {
      font-size: 16px;
      margin-bottom: 22px;
    }
  }

  .field {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .contentForgoten {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h2 {
      font-size: 16px;
      color: $colorBlack;
      margin-bottom: 38px;
      font-weight: 500;
    }

    div {
      margin-bottom: 0px;
    }
  }

  .fieldForgot {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .forgotPassword {
      display: grid;
      place-items: center;

      border: none;
      background: transparent;
      margin-top: -16px;
      margin-bottom: 14px;
      cursor: pointer;

      &:hover {
        color: $colorPrimary;
      }
    }
  }

}


