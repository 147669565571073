@import "../../../Styles/Mixin.scss";

.ContainerPodcast {
  margin-top: -20px;
  p {
    font-size: 14px;
    color: $colorBlack;
  }
}

.podcastItems {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(252px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
