@import "../../../Styles/Mixin.scss";

.youtube {
  .destaque {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 28px;
    align-items: flex-start;
    > span {
      width: 100%;
      margin-bottom: 0px;
      display: flex;
    }
    .wallpaper {
      border-radius: 4px;
      width: 100%;
    }
  }
  a {
    text-decoration: none;
    .textDestaque {
      display: flex;
      flex-direction: column;
      font-size: 9px;
      line-height: 12px;
      color: $colorPrimary;
      text-decoration: none;
      b {
        font-size: 14px;
        line-height: 21px;
        color: $colorGray;
        margin-top: 4px;
      }
  }
  }
  .list {
    border-top: 1px solid #fff;
    margin-top: 28px;
    padding-top: 28px;
    .item {
      display: flex;
      text-decoration: none;
      margin-bottom: 18px;
      align-items: center;
      .image {
        min-width: 109px;
        min-height: 63px;
        background-size: cover;
        align-items: center;
        justify-content: center;
        position: relative;
        display: flex;
        border-radius: 4px;
        > span {
          width: 100%;
          height: 63px;
          display: flex;
        }
      }
      .text {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        a {
          font-size: 9px;
          line-height: 12px;
          color: $colorGray;
          text-transform: uppercase;
          text-decoration: none;
        }
        b {
          font-size: 14px;
          line-height: 21px;
          color: $colorGray;
          margin-top: 4px;
        }
      }
    }
  }
}
.c-2 {
  .youtube {
    .list {
      .item {
        flex-direction: column;
        border-bottom: 1px solid rgba(#000, .1);
        padding-bottom: 5px;
        .image {
          width: 100%;
        }
        .text {
          width: 100%;
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
  }
}