@import "../../../Styles/Mixin.scss";

.agenda {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    .buttonsAgenda {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        gap: 6px;
        margin-bottom: 21px;
    }
    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    @media (max-width: 1180px) {
        .buttonsAgenda {
            grid-template-columns: 1fr;
            // button {
            //     margin-left: 0px;
            //     margin-bottom: 4px;
            //     width: 100%;
            // }
        }
    }
}
