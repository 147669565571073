@import "../../../Styles/Mixin.scss";

.publicationsColumnist {
  margin-bottom: 8px;
  .image {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    .wallpaper {
      border-radius: 4px;
      width: 100%;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    .publicationsPodasts {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 12px;


      .publicationsPodcastDescription {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        width: 100%;

        > div {
          p {
            font-size: 14px;
            white-space: nowrap;
          }

          p + p {
            margin-top: 10px;
          }
        }
      }
    }

    audio {
      width: 100%;
    }

    h3 {
      line-height: 15px;
      color: $colorBlack;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 12px;
    }
  }
}
