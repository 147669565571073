@import "../../Styles/Mixin.scss";

#containerProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menu-profile {
    .register {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff;
      height: 56px;
      border-radius: 4px;
      padding: 0 12px;
  
      button {
        background: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        font-size: 14px;
        color: colorDarkContent;
        font-weight: 600;
        white-space: nowrap;
        transition: all 0.3s;
        padding: 6px 8px;
        margin: 0 5px;
  
        &:hover {
          cursor: pointer;
          border: 1px solid transparent;
          background: $colorPrimary;
          color: #fff;
        }
      }
    }
  
    .photo {
      background-size: cover;
      background-color: #ccc;
      background-position: center;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      cursor: pointer;
      overflow: hidden;
      input {
        opacity: 0;
        visibility: hidden;
      }
    }
    .user {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      justify-content: center;
  
      span {
        font-size: 9px;
        line-height: 12px;
        color: $colorBlack !important;
        b {
          color: $colorBlack !important;
        }
      }
      a {
        color: rgba($colorPrimary, 0.4) !important;
        font-size: 10px;
        margin-top: 4px;
        text-decoration: none;
      }
    }
    .logout {
      margin-left: 50px;
      font-size: 9px;
      line-height: 12px;
      color: $colorPrimary;
      text-decoration: underline;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .burguerMenu {
    display: none;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;

    border: none;
    border-radius: 4px;
    margin-right: 12px;


    background: $colorWhite;
    cursor: pointer;
  }

  @media(max-width: 800px) {
    margin-bottom: 12px;
    .menu-profile {
      .logout {
        margin-left: 0px;
      }
      .register {
        justify-content: space-between;
        // height: 40px;
      }
    }
    .burguerMenu {
      display: flex;
      width: 40px;
      height: 40px;
    }
  }
}

